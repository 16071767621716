.thead {
  background-color: var(--mantine-color-black-1) !important;
  color: var(--mantine-color-black-10);
  border-top-left-radius: 8px !important;
  font-size: 1rem !important;
}

.thead .tr .th:first-child {
  background-color: var(--mantine-color-black-1) !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.thead .tr .th:last-child {
  background-color: var(--mantine-color-black-1) !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.thead .tr .th:nth-child(2) {
  border-left: 1px solid var(--mantine-color-black-2) !important;
}

.td {
  padding: 12px !important;
}

.tr {
  border-bottom: none !important;
  border-top-left-radius: 8px !important;
}

.tr:hover {
  background-color: var(--mantine-color-black-1);
}
