.control {
  background-color: var(--mantine-color-black-1) !important;
  color: var(--mantine-color-black-10) !important;
}

.control[aria-expanded='false'] {
  border-radius: 0.5rem !important;
  border: 1px solid var(--mantine-color-black-2) !important;
}

.control[aria-expanded='true'] {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom: 1px solid var(--mantine-color-black-2) !important;
}

.chevron svg,
.chevron {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.content {
  padding: 0rem !important;
}
