/* src/theme/components/DataDisplay/Badge/Badge.module.css */
.root {
  border-radius: 0.5rem !important;
  text-transform: inherit !important;
  --badge-padding-x: 0.5rem !important;
  font-family: 'Space Grotesk' !important;
}
.label {
  font-weight: bolder !important;
}
.root[data-variant='neutral'] {
  color: #20212d !important;
  background-color: #fff !important;
  border: 1px solid #cfd0dd;
}
.root[data-variant='red'] {
  color: #ee434e !important;
  background-color: #ee434e1f !important;
}
.root[data-variant='yellow'] {
  color: #f48d14 !important;
  background-color: #f48d141f !important;
}
.root[data-variant='blue'] {
  color: #091fe8 !important;
  background-color: #091fe81f !important;
}
.root[data-variant='green'] {
  color: #10a84d !important;
  background-color: #12c1381f !important;
}
.root[data-variant='gray'] {
  color: #20212d !important;
  background-color: #f0f1f5 !important;
}
.root[data-variant='darkblue'] {
  color: #ffffff !important;
  background-color: #0a78c3 !important;
}
.root[data-variant='purple'] {
  color: #770594 !important;
  background-color: #fef0f7 !important;
}
.root[data-variant='dot'] {
  border: none !important;
  background-color: transparent;
}
