/* ag-theme-acmecorp.css */
.ag-theme-acmecorp {
  --ag-header-background-color: #f0f1f5;
  --ag-row-hover-color: #f0f1f5;
  --ag-row-border-radius: 8px;
  font-family: 'Space Grotesk' !important;
}

.ag-theme-acmecorp .ag-header {
  border: none;
  border-radius: 8px;
}
.ag-theme-acmecorp .ag-root-wrapper {
  border: none;
}
.ag-theme-acmecorp .ag-row {
  border: none;
  border-radius: 8px;
}
.ag-theme-acmecorp .ag-cell {
  place-content: center;
}
.ag-theme-acmecorp .ag-header-cell-resize::after {
  height: 48px;
  width: 1px;
  top: 0px;
}
.ag-theme-acmecorp .ag-paging-panel {
  display: none;
}
.ag-theme-acmecorp .ag-header-cell-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #20212d;
}
