.root[data-variant='gray'] {
  background-color: var(--mantine-color-white) !important;
  padding-left: 1.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  --bq-bd: #b1b3c8 !important;
}
.root[data-variant='green'] {
  background-color: var(--mantine-color-white);
  padding-left: 1.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  --bq-bd: #10a84d !important;
}
