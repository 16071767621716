.label[data-variant='primary'] {
  color: var(--mantine-color-black-white) !important;
}

.description[data-variant='primary'] {
  color: var(--mantine-color-white) !important;
}

.label[data-variant='dark'] {
  color: var(--mantine-color-black-6) !important;
}

.description[data-variant='dark'] {
  color: var(--mantine-color-black-6) !important;
}
.label {
  color: var(--mantine-color-black-6) !important;
  font-size: 0.75rem !important;
}

.root[data-variant='dark'] {
  border: none !important;
}
