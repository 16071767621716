.root {
  --button-height-xs: calc(1.875rem * var(--mantine-scale));
  --button-height-sm: calc(2.25rem * var(--mantine-scale));
  --button-height-md: calc(2.625rem * var(--mantine-scale));
  --button-height-lg: calc(3.125rem * var(--mantine-scale));
  --button-height-xl: calc(3.75rem * var(--mantine-scale));
}

.root[data-variant='primary'] {
  background-color: var(--mantine-color-blue-5);
  border-bottom: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-blue-6);
}

.root[data-variant='primary']:hover {
  background-color: var(--mantine-color-blue-6);
}

.root[data-variant='primary']:disabled {
  background-color: var(--mantine-color-blue-3);
  border-bottom: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-blue-4);
  color: var(--mantine-color-white);
}

.root[data-variant='secondary'] {
  background-color: transparent;
  color: var(--mantine-color-blue-5);
}

.root[data-variant='secondary']:hover {
  background-color: transparent;

  color: var(--mantine-color-blue-7);
}

.root[data-variant='secondary']:disabled {
  background-color: var(--mantine-color-white);

  color: var(--mantine-color-blue-3);
}

.root[data-variant='tertiary'] {
  background-color: var(--mantine-color-black-1);
  color: var(--mantine-color-black-8);
  border-bottom: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-black-2);
}

.root[data-variant='tertiary']:hover {
  background-color: var(--mantine-color-black-2);
  color: var(--mantine-color-black-10);
  border-bottom: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-black-2);
}

.root[data-variant='tertiary']:disabled {
  background-color: var(--mantine-color-black-1);
  color: var(--mantine-color-black-5);
  border-bottom: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-black-2);
}

.root[data-variant='subtle'] {
  color: var(--mantine-color-blue-6);
}

.root[data-variant='subtle']:hover {
  color: var(--mantine-color-blue-8);
  background-color: transparent;
}

.root[data-variant='subtle']:disabled {
  color: var(--mantine-color-blue-3);
  background-color: transparent;
}
