.root {
  --input-height-xs: calc(1.875rem * var(--mantine-scale));
  --input-height-sm: calc(2.25rem * var(--mantine-scale));
  --input-height-md: calc(2.625rem * var(--mantine-scale));
  --input-height-lg: calc(3.125rem * var(--mantine-scale));
  --input-height-xl: calc(3.75rem * var(--mantine-scale));
}

.section[data-position='left'] {
  color: var(--mantine-color-black-6) !important;
}

.rightSection[data-variant='dark'] {
  color: var(--mantine-color-black-6);
}

.input[data-variant='outline'] {
  border: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-black-4) !important;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-black-6);
}

.input[data-variant='outline']:focus {
  border: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-blue-6) !important;
  box-shadow: 0px 0px 0px 4px rgba(63, 93, 248, 0.16);
  color: var(--mantine-color-black-10);
}
.input[data-variant='outline']:valid {
  color: var(--mantine-color-black-10);
}

.input[data-variant='solid'] {
  border: none !important;
  background-color: var(--mantine-color-black-1);
}

.input[data-variant='solid']:focus {
  border: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-blue-6) !important;
  box-shadow: 0px 0px 0px 4px rgba(63, 93, 248, 0.16);
  color: var(--mantine-color-black-10);
}

.input[data-variant='solid']:valid {
  color: var(--mantine-color-black-10);
}

.input[data-variant='dark'] {
  border: none !important;
  background-color: var(--mantine-color-black-10);
  color: var(--mantine-color-white);
}

.input[data-variant='dark']:focus {
  border: calc(0.125rem * var(--mantine-scale)) solid var(--mantine-color-blue-6) !important;
  box-shadow: 0px 0px 0px 4px rgba(63, 93, 248, 0.16);
  background-color: var(--mantine-color-black-10);
  color: var(--mantine-color-black-10);
}

.input[data-variant='dark']:valid {
  background-color: var(--mantine-color-black-10) !important;
  color: var(--mantine-color-white);
}
