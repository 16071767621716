.root {
  background-color: transparent !important;
  gap: 0.5rem !important;
}
.control {
  --_separator-color: transparent !important;
}
.label[data-active='true'] {
  background-color: var(--mantine-color-black-8);
  color: white;
}

.label {
  border: 1px solid var(--mantine-color-black-3);
  border-radius: 8px;
}
