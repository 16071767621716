.calendarHeaderControlIcon {
  color: var(--mantine-color-black-10) !important;
  width: 24px !important;
}
.calendarHeaderControlIcon[data-direction='next'] {
  transform: rotate(270deg) !important;
}
.calendarHeaderControlIcon[data-direction='previous'] {
  transform: rotate(90deg) !important;
}
.calendarHeader {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
